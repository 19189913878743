import './App.css';

import {BrowserRouter} from "react-router-dom";
import {Routes, Route} from "react-router-dom";
import IT from "./Views/IT";
import Event from "./Views/Event";
import Impressum from "./Views/Impressum";
import Frontpage from "./Views/Frontpage";
import ComingSoon from "./Views/ComingSoon";
import ExternPrices from "./Views/ExternPrices";
import IEM from "./Views/IEM";
import DiscordBots from "./Views/DiscordBots";


function App() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<ComingSoon/>}/>
                <Route path="/beta" element={<Frontpage/>}/>
                <Route path="impressum" element={<Impressum/>}/>
                <Route path="it" element={<IT/>}/>
                <Route path="events" element={<Event/>}/>
                <Route path="externStreams" element={<ExternPrices/>}/>
                <Route path="discordBot" element={<DiscordBots/>}/>
                <Route path="IEM" element={<IEM/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
