
import {Link} from "react-router-dom";
import {EventLink, IEMLink, ITLink} from "../Links/NavLinks";
import ThemeSelector from "./ThemeSelector";

function NavItems() {
    return (
        <div>
            <li><ITLink/></li>
            <li><EventLink/></li>
        </div>
    )
}

function LargeNavbar() {
    return (
        <div className="navbar-center hidden lg:flex">
            <ul className="menu menu-horizontal p-0">
                <li><ITLink/></li>
                <li><EventLink/></li>
            </ul>
        </div>
    )
}

function SmallNavbar() {
    return (
        <div className="dropdown">
            <label tabIndex="0" className="btn btn-ghost lg:hidden">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M4 6h16M4 12h8m-8 6h16"/>
                </svg>
            </label>
            <ul tabIndex="0"
                className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
                <NavItems/>
            </ul>
        </div>
    )
}

function Navbar() {


    return (
        <div className="navbar bg-base-100">
            <div className="navbar-start">
                <SmallNavbar/>
                <div className="navbar-start">
                    <Link to="/" className="btn btn-ghost normal-case text-xl">LPA UG</Link>
                </div>
            </div>

            <LargeNavbar/>

            <div className="navbar-end space-x-3">
                <ThemeSelector/>
                <a className="btn btn-primary">Kontakt</a>
            </div>
        </div>
    )
}


export default Navbar;