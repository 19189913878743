import Navbar from "../Navigation/Navbar";
import Hero from "../Components/Frontpage/Hero";

function Frontpage() {
    return (
        <div>
            <div className="flex flex-col h-screen">
                <Navbar/>
                <div className="hero flex justify-center items-center flex-grow bg-base-200">
                    <Hero/>
                </div>
            </div>
        </div>
    )
}

export default Frontpage;