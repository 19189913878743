import Navbar from "../Navigation/Navbar";

function Event() {
    return (
        <div>
            <Navbar/>
            <h1>Events</h1>
        </div>
    )
}

export default Event;