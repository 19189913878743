import Navbar from "../Navigation/Navbar";
import DiscordBot from "../Components/Pricing/DiscordBot";

function ExternPrices() {
    return (
        <div>
            <Navbar/>

            <DiscordBot />
        </div>
    )
}

export default ExternPrices;