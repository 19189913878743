import Navbar from "../Navigation/Navbar";

function IEM() {
    return (
        <div>
            <Navbar/>
            <h1>IEM Cologne</h1>
        </div>
    )
}

export default IEM;