import PriceLevel from "./Components/PriceLevel";

function DiscordBot(){
    return (
        <section className="bg-white dark:bg-gray-900">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Professional Hosting for your product</h2>
                    <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">This is our pricing for hosting. We also develop custom products - contact us if you are interested.</p>
                </div>
                <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
                    <PriceLevel Caption={"Light"}
                                Description={"Hosting only. Changes can be done, but will be billed."}
                                Price={"1,99€"}
                                Features={['Hosting']}
                    />

                    <PriceLevel Caption={"Future-proof"}
                                Description={"We'll make sure that your product keeps running 24/7."}
                                Price={"3,99€"}
                                Features={['Hosting', 'Monitoring', 'Small changes*, if the product stops working']}
                    />

                    <PriceLevel Caption={"Adaptive"}
                                Description={"You are allowed to request small changes* for your product."}
                                Price={"7,99€"}
                                Features={['Hosting', 'Monitoring', 'Small changes*, if the product stops working',
                                'Small changes* if the requirements change']}
                    />

                </div>

                (*) Small change = 1 hour of work per month. Unused hours will not add up.

            </div>
        </section>
    )
}

export default DiscordBot