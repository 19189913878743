import {useEffect} from 'react'
import {themeChange} from 'theme-change'

function ThemeSelector() {
    useEffect(() => {
        themeChange(false)
        // 👆 false parameter is required for react project
    }, [])

    return (
            <select className="select select-ghost " data-choose-theme>
                <option disabled selected>Theme</option>
                <option value="light">Light</option>
                <option value="dark">Dark</option>
                <option value="lemonade">Spring</option>
                <option value="cyberpunk">Cyberpunk</option>
            </select>
    )
}

export default ThemeSelector;