import Navbar from "../Navigation/Navbar";

function IT() {
    return (
        <div>
            <Navbar/>
            <h1>IT</h1>
        </div>
    )
}


export default IT;