import {Link} from "react-router-dom";

export function ITLink() {
    return <Link to="/it">IT</Link>;
}

export function EventLink() {
    return <Link to="/events">Events</Link>;
}

export function IEMLink() {
    return <Link to="/IEM">IEM Cologne 2022</Link>;
}