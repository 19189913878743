import NotificationMessage from "../Components/NotificationMessage";
import Navbar from "../Navigation/Navbar";

function ComingSoon() {
    return (
        <div className="flex flex-col h-screen">
            <Navbar />
            <NotificationMessage />
        </div>)
}


export default ComingSoon;