import PriceLevelFeature from "./PriceLevelFeature";

function PriceLevel(props){
    return (
        <div
            className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
            <h3 className="mb-4 text-2xl font-semibold">{props.Caption}</h3>
            <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">{props.Description}</p>
            <div className="flex justify-center items-baseline my-8">
                <span className="mr-2 text-5xl font-extrabold">{props.Price}</span>
                <span className="text-gray-500 dark:text-gray-400">/month</span>
            </div>
            {/* List */}
            <ul role="list" className="mb-8 space-y-4 text-left">
                {props.Features.map((feature) => <PriceLevelFeature Text={feature}/>)}
            </ul>
        </div>

    )
}

export default PriceLevel