function ExternalCasts(){

    return (
        <div>
            <div className="lg:flex items-center justify-between space-y-3">
                <div className="lg:w-1/2 w-full">
                    <h1 role="heading"
                        className="md:text-5xl text-3xl font-bold leading-10 mt-3 text-primary">Externe Casts</h1>
                    <p role="contentinfo" className="text-base leading-5 mt-5 text-secondary">{`Ein externer Cast ist ein Cast der nicht über unseren Twitch Kanal gestreamt wird.
                    Externe Casts berechnen wir mit den folgenden Preisen. 
                    Ein Match besteht aus 2 Games (bo2).`}</p>
                </div>
                <div className="xl:w-1/2 lg:w-7/12 relative w-full lg:mt-0 mt-12 md:px-8" role="list">
                    <img src="https://i.ibb.co/0n6DSS3/bgimg.png" className="absolute w-full -ml-12 mt-24"
                         alt="background circle images"/>
                    <div role="listitem"
                         className="bg-white dark:bg-gray-800 cursor-pointer shadow rounded-lg p-8 relative z-30">
                        <div className="md:flex items-center justify-between">
                            <h2 className="text-2xl font-semibold leading-6 text-gray-800 dark:text-white">Budget</h2>
                            <p className="text-2xl font-semibold md:mt-0 mt-4 leading-6 text-gray-800 dark:text-white">8 €
                            <span
                                className="font-normal text-base">/match</span></p>
                        </div>
                        <p className="md:w-80 text-base leading-6 mt-4 text-gray-600 dark:text-gray-200">Termine können wir in dieser Preisklasse erst 48 Stunden vor Matchstart zu oder absagen</p>
                    </div>
                    <div role="listitem"
                         className="bg-white dark:bg-gray-800 cursor-pointer shadow rounded-lg mt-3 flex relative z-30">
                        <div className="w-2.5 h-auto bg-indigo-700 rounded-tl-md rounded-bl-md"></div>
                        <div className="w-full p-8">
                            <div className="md:flex items-center justify-between">
                                <h2 className="text-2xl font-semibold leading-6 text-gray-800 dark:text-white">Standard</h2>
                                <p className="text-2xl md:mt-0 mt-4 font-semibold leading-6 text-gray-800 dark:text-white">15 €<span
                                    className="font-normal text-base">/match</span></p>
                            </div>
                            <p className="md:w-80 text-base leading-6 mt-4 text-gray-600 dark:text-gray-200">Wir halten euch einen Termin pro Match frei.</p>
                        </div>
                    </div>
                    <div role="listitem"
                         className="bg-white dark:bg-gray-800 cursor-pointer shadow rounded-lg p-8 relative z-30 mt-7">
                        <div className="md:flex items-center justify-between">
                            <h2 className="text-2xl font-semibold leading-6 text-gray-800 dark:text-white">Premium</h2>
                            <p className="text-2xl md:mt-0 mt-4 font-semibold leading-6 text-gray-800 dark:text-white">20 €<span
                                className="font-normal text-base">/match</span></p>
                        </div>
                        <p className="md:w-80 text-base leading-6 mt-4 text-gray-600 dark:text-gray-200">Wir geben euren Terminen maximale Priorität und halten euch gerne mehrere Termine pro Match frei, damit wir euch sicher casten können.</p>
                    </div>
                </div>
            </div>


        </div>

    )
}

export default ExternalCasts;