import PriceLevelFeatureIcon from "./PriceLevelFeatureIcon";

function PriceLevelFeature(props){
    return (
        <li className="flex items-center space-x-3">
            <PriceLevelFeatureIcon/>
            <span>{props.Text}</span>
        </li>
    )
}

export default PriceLevelFeature