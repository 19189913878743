import Navbar from "../Navigation/Navbar";

function Impressum() {
    return (
        <div>
            <Navbar/>
            <b className={"text-2xl text-center"}>Impressum</b>
            <p className={"text-center"}>
            Angaben gemäß § 5 TMG<br />            LPA UG (haftungsbeschränkt)<br />            Breslauer Straße 46<br />            26919 Brake (Unterweser)<br /><br />            Handelsregister: HRB 217488<br />            Registergericht: Amtsgericht Oldenburg<br /><br />            Vertreten durch:<br />            Daniel Wylega<br />            Tobias Wylega<br /><br />            Kontakt<br />            Telefon: +49 4401 5058984<br />            E-Mail: info@lpagents.de<br /><br />            Bankverbindung<br />            LT58 3250 0255 0300 7583<br />            REVOLT21<br />            EU-Streitschlichtung<br />            Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: https://ec.europa.eu/consumers/odr/.<br />            Unsere E-Mail-Adresse finden Sie oben im Impressum.<br /><br />            Verbraucher­streit­beilegung/Universal­schlichtungs­stelle<br />            Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
            </p>
        </div>
    )
}

export default Impressum;