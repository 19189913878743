import Navbar from "../Navigation/Navbar";
import NotificationMessage from "../Components/NotificationMessage";
import ExternalCasts from "../Components/Pricing/ExternalCasts";

function ExternPrices() {
    return (
        <div>
            <Navbar/>
            <NotificationMessage />

            <ExternalCasts/>
        </div>
    )
}

export default ExternPrices;