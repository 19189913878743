import {EventLink, ITLink} from "../../Links/NavLinks";

function Hero(){
    return(
        <div className="hero-content text-center">
            <div className="max-w-md">
                <h1 className="text-5xl font-bold">Willkommen bei der LPA UG</h1>
                <p className="py-6">Wir bieten verschiedene Dienstleistungen. Mit den Buttons können die
                    verschiedenen Abteilungen besucht werden.</p>
                <button className="btn btn-secondary"><ITLink/></button>
                <button className="btn btn-primary"><EventLink/></button>
            </div>
        </div>
    )
}

export default Hero;